@import "~@chatscope/chat-ui-kit-styles/themes/default/variables";

$di-focus-visible-outline: 2px solid red;

:root {
  --di-a11y-btn-color: #{$color-primary-dark};
  --di-focus-visible-outline: #{$di-focus-visible-outline};
  --di-wc-header-btn-color: #{$color-primary-dark};
  --di-wc-header-btn-border-color: #{$color-primary-dark};
}

// Dodatkowe klasy nadpisujące domyślne wartości komponentów chat

.cs-main-container {
  overflow: visible; // Żeby działał outline
  .cs-chat-container,
  .cs-conversation-header
  {
    z-index: auto;
  }
  .cs-message-list {
    z-index: 1;
    &:focus-visible {
      outline: var(--di-focus-visible-outline);
    }
    
    .cs-typing-indicator {
      // Miejsce na outline
      padding: calc( 0.5em - 4px) 0.5em;
      height: calc(1.25em + 4px);
      .cs-typing-indicator__text {
        width: 100%;
      }
    }
  }
}

.cs-message__content {
  border: var(--di-message-content-border);
}

.cs-message--incoming .cs-message__content {
  border: var(--di-message-content-incoming-border);
}

.cs-message-input__content-editor-wrapper {
  border: var(--di-message-input-content-editor-wrapper-border);
}

.cs-chat-container .cs-message-input {
  padding: 0.6rem 0;
}

.cs-conversation-header {
  padding: 0.6rem; // Ustawienie na rem (domyślnie jest w em) żeby zwiększenie fontu nie zwiększało paddingu
}

:focus-visible {
  outline: var(--di-focus-visible-outline);
}

.cs-message-input__tools:last-child .cs-button:last-child {
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.cs-chat-container .cs-message-input .cs-message-input__content-editor-wrapper:last-child {
  margin-right:0;
}

.cs-button.cs-button--send,
.cs-button.cs-button--arrow
{
  &:focus-visible {
    outline: var(--di-focus-visible-outline);
  }
}

// Resetujemy outline na focusie contentu wiadomości, bo zamiast tego pokazujmey outline na cąłej wiadomości
.cs-message [data-focusable-content] {
  outline: 0;
}
.cs-message.focused {
  outline: var(--di-focus-visible-outline);
}

// Tooltip
.di-tooltip {
  --bs-tooltip-color: var(--cs-tooltip-color);
}

.tooltip-inner {
  border: 1px solid var(--cs-tooltip-border-color) !important;
}

.tooltip-arrow {
  &::before {
    border-top-color: var(--cs-tooltip-arrow-color) !important;
  }
}
